.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .contentWrapper {
    width: 100%;
    padding-top: 5rem;
    margin: 0 auto;

    a {
      text-decoration: none;
      color: #0794e7;

      &:hover {
        color: #1273ac;
      }
      &:active,
      &:focus {
        color: #0794e7;
      }
    }
  }
}
