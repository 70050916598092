@import "./src/styles/variables.module.scss";

.textBlock {
  padding: 40px 20px;
}

.subtitle {
  margin-bottom: 40px;
}

.logo {
  margin: 0;
  font-size: var(--font-size-logo-intro-mobil);
  font-weight: bold;
  transition: 0.2s;
  // color: var(--color-background);
  color: var(--color-font);
  top: 300px;
  position: absolute;
  left: calc(50% - 100px);

  .and {
    color: var(--color-primary);
    display: block;
    line-height: 0;
  }
}

.logoBackgroundWrap {
  height: 600px;
}

.logoBackground {
  width: 1050px !important;
  height: auto !important;
  left: 23% !important;
}

.farmaBackground {
  top: -100px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonis {
  display: block;
  width: 200px;
  margin-top: 35px;
  margin-bottom: 50px;
  padding: 15px 30px;
  background-color: var(--color-secondary);
  border: 0;
  border-radius: 5px;
  color: var(--color-font) !important;
  font-size: inherit;
  cursor: pointer;

  &:hover {
    background-color: var(--color-secondary-hover);
  }
  &:active {
    background-color: var(--color-secondary-active);
  }
}

@media only screen and (min-width: 700px) {
  .logoBackground {
    width: 120% !important;
    left: 40% !important;
  }
  .logoBackgroundWrap {
    height: 800px;
  }
  .logo {
    margin: 0;
    font-size: var(--font-size-logo-intro);
    top: 450px !important;
  }
}
