.content {
  padding: 20px 20px;
}

.subtitle {
  margin-bottom: 40px;
}

.informationBox {
  width: 100%;
  padding: 10px 0 30px 0;

  .question {
    font-weight: bold;
    font-size: var(--font-size-bigger);
    margin-bottom: 10px;
    text-align: left;
  }

  .answer {
    text-align: justify;
  }
}

.paralexContent {
  background-color: var(--color-background);
  font-weight: bold;
  padding: 20px 40px;
  position: absolute;

  h2 {
    padding: 0;
    margin: 0;
  }
}

.mapaBackgroundWrap {
  height: 600px;

  .paralexContent {
    top: calc(50% - 47px);
    left: calc(50% - 133px);
  }
}

.mapaBackground {
  width: 1100px !important;
  height: auto !important;
}

.introBackgroundWrap {
  height: 600px;

  .paralexContent {
    padding: 20px;
    top: calc(50% - 93px);
    left: calc(50% - 171px);
  }
}

.farmaBackgroundWrap {
  height: 300px;

  .paralexContent {
    top: calc(50% - 47px);
    left: calc(50% - 133px);
  }
}

.farmaBackground {
  width: 750px !important;
  height: auto !important;
}

@media only screen and (min-width: 700px) {
  .informationBox {
    width: 50% !important;
    text-align: center;
    display: block;
    margin: 0 auto;
  }

  .introBackgroundWrap {
    .paralexContent {
      padding: 20px 40px !important;

      top: calc(50% - 93px);
      left: calc(50% - 191px);
    }
  }

  .farmaBackgroundWrap {
    height: 400px;
  }

  .farmaBackground {
    width: auto !important;
    top: -150px;
  }

  .mapaBackground {
    width: 100% !important;
  }
}
