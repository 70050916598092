// COLORS

$white: #fff;

$blue-100: #425988;
$blue-150: #354970;
$blue-200: #293958;
$blue-750: rgb(49, 55, 68);
$blue-800: #282c34;

$green-300: #13911d;
$green-350: #13791b;
$green-400: #0f5315;

$grey_100: #d4d4d4;
$grey_200: #b9b9b9;
$grey_250: #a7a7a7;
$grey_800: #121212;

$red_300: #cc0000;

$black: #000;

// VARIABLES

:root {
  --color-background: #{$blue-800};
  --color-background-header: #{$blue-750};
  --color-shadow: #{$grey_800};

  --color-primary: #{$blue-100};
  --color-primary-hover: #{$blue-150};
  --color-primary-active: #{$blue-200};

  --color-secondary: #{$green-300};
  --color-secondary-hover: #{$green-350};
  --color-secondary-active: #{$green-400};

  --color-font: #{$white};

  --color-header-link: #{$grey_100};
  --color-header-link-hover: #{$grey_200};
  --color-header-link-active: #{$grey_250};

  --color-button: var(--color-primary);

  --color-error: #{$red_300};

  --font-size-default: 16px;
  --font-size-bigger: 18px;
  --font-size-smaller: 14px;

  --font-size-logo: 2rem;
  --font-size-logo-shrinked: 1.5rem;

  --font-size-logo-mobil: 1.5rem;
  --font-size-logo-shrinked-mobil: 1.2rem;

  --font-size-logo-intro: 4rem;
  --font-size-logo-intro-mobil: 4rem;
}
