@import "./src/styles/variables.module.scss";

.header {
  position: relative;
  height: 4.5rem;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  background-color: var(--color-background);
  z-index: 9;
  transition: 0.2s;

  &.shrinked {
    box-shadow: 0 0 30px var(--color-shadow);
    background-color: var(--color-background-header);
    height: 2.5rem;

    .logo {
      font-size: var(--font-size-logo-shrinked-mobil);
    }
  }
}

/* LOGO */

.logo {
  margin: 0;
  font-size: var(--font-size-logo-mobil);
  font-weight: bold;
  transition: 0.2s;

  a {
    text-decoration: none;

    &,
    &:active,
    &:focus {
      color: var(--color-header-link);
    }

    &:hover {
      color: var(--color-header-link-hover);
    }
  }
}

/* NAV MOBILE */

.desktopNav {
  display: none;
}
.mobileNav {
  display: block;
}

.mobileMenuWrapper {
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;

  &.open {
    display: flex;
  }
  &.close {
    display: none;
  }
}

.mobileMenuOuter {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}
.mobileMenu {
  width: 50%;
}

.mobileMenu nav {
  background-color: #454d5c;
  box-shadow: 0px 0 10px var(--color-shadow);
  padding: 10px 0;
  box-sizing: content-box;
  text-align: left;
  height: 100vh;
}
nav ul {
  padding: 0;
  margin: 0;
}
nav li {
  display: block;
  padding: 30px 20px;
}
nav li a {
  display: block;
  width: 100%;
  color: var(--color-header-link);
  text-decoration: none;
}
nav li a:hover,
nav li a:active,
nav li a:focus {
  color: var(--color-header-link-hover);
}

/* NAV DESKTOP */

@media only screen and (min-width: 700px) {
  .header {
    .logo {
      font-size: var(--font-size-logo) !important;
    }
    &.shrinked .logo {
      font-size: var(--font-size-logo-shrinked) !important;
    }
  }

  .mobileNav {
    display: none !important;
  }
  .desktopNav {
    display: block !important;
  }

  nav ul {
    display: flex;
  }
  nav li {
    list-style: none;
    display: inline !important;
    padding: 0 20px !important;
  }
  nav li a {
    text-decoration: underline;
  }
  nav li a:hover {
    text-decoration: none;
  }
}
