@import "./src/styles/variables.module.scss";

.content {
  padding: 20px 20px;
}

.loginForm {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  .error {
    font-size: var(--font-size-smaller);
    color: var(--color-error);
    padding: 10px 25% 0 25%;
  }

  .inputContainer {
    padding-bottom: 10px;
    text-align: left;

    input {
      width: 50%;
      padding: 5px;
    }

    label {
      display: inline-block;
      width: 25%;
      text-align: right;
      padding-right: 10px;
    }
  }

  .buttonContainer {
    padding: 20px;

    input {
      min-height: 30px;
      padding: 5px 30px;
      background-color: var(--color-primary);
      border: 0;
      border-radius: 5px;
      color: var(--color-font);
      font-size: inherit;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-hover);
      }
      &:active {
        background-color: var(--color-primary-active);
      }
    }
  }
}

.invitation {
  padding-bottom: 10px;
  // border-bottom: 1px solid;
}

.introBackgroundWrap {
  height: 600px;

  .paralexContent {
    background-color: var(--color-background);
    // font-weight: bold;
    padding: 20px;
    position: absolute;
    max-width: 450px;

    top: calc(50% - 93px);
    left: calc(50% - 185px);
    margin: 0 10px;
  }
}

.introBackground {
  width: auto !important;
  height: 900px !important;
  top: -300px;
}

.guessForm {
  margin: 0 auto;
  .infoBlock {
    border: 1px solid var(--color-primary-hover);
    background-color: var(--color-primary-active);
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0 0;
    font-weight: normal;
    transition: 0.1s;

    &.collapsed {
      height: 0;
      font-size: 0;
      line-height: 0;
      padding: 0;
      border: 0;
    }
  }

  .inputContainer {
    // border-bottom: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    margin: 0 auto;
    padding: 30px 0 30px 0;
    transition: 0.1s;

    input[type="text"] {
      width: 100%;
      height: 30px;
    }

    &.collapsed {
      height: 0;
      font-size: 0;
      line-height: 0;
      padding: 0;
      border: 0;

      input,
      label {
        display: none !important;
      }
    }

    .formQuestion {
      padding: 0px 0 10px 0;
      font-weight: bold;
    }

    .radio {
      margin: 10px 0;

      label {
        display: flex;
        margin: 0;
        font-weight: normal;
      }

      input {
        width: 15px;
        height: 15px;
        margin: 5px 10px 0 10px;
      }
    }

    .selectWithLabel {
      width: 100%;
      margin-bottom: 20px;

      span.selectLabel {
        display: inline-block;
        margin-left: 10px;
        width: 70px;
      }
    }

    .selectPeople {
      width: 100px;
      display: inline-block;
      margin: 0 10px;
    }

    .selectRoom {
      width: calc(100% - 100px);
      display: inline-block;
      margin: 0 10px;
    }

    :global(.select__control) {
      min-height: 25px;
      background-color: var(--color-button);

      :global(.select__value-container) {
        height: 20px;
        align-content: center;
        justify-content: center;

        :global(.select__single-value) {
          color: var(--color-font);
        }
      }

      :global(.select__indicators) {
        height: 22px;

        :global(.select__indicator-separator) {
          margin: 4px 0;
        }

        :global(.select__indicator) {
          padding: 0 10px;

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    :global(.select__menu) {
      background-color: var(--color-button);

      :global(.select__menu-list) {
        :global(.select__option) {
          &:global(.select__option--is-focused) {
            background-color: var(--color-primary-hover);
          }
          &:global(.select__option--is-selected) {
            background-color: var(--color-primary-active);
          }
        }
      }
    }
  }

  button {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 50px;
    min-height: 50px;
    padding: 15px 30px;
    background-color: var(--color-secondary);
    border: 0;
    border-radius: 5px;
    color: var(--color-font);
    font-size: inherit;
    cursor: pointer;

    &:hover {
      background-color: var(--color-secondary-hover);
    }
    &:active {
      background-color: var(--color-secondary-active);
    }
  }
}

@media only screen and (min-width: 700px) {
  .guessForm {
    width: 50% !important;
    .inputContainer {
      .radio {
        margin: 5px 0 !important;
      }
      .radio input {
        margin: 5px 10px 0 10px !important;
      }
      .selectWithLabel {
        margin-bottom: 10px !important;
      }
    }
    button {
      width: 50%;
      margin-bottom: 150px;
    }
  }

  .introBackground {
    width: 100% !important;
    height: auto !important;
    top: -300px;
  }

  .introBackgroundWrap {
    .paralexContent {
      padding: 20px 40px !important;

      top: calc(50% - 93px);
      left: calc(50% - 225px);
    }
  }
}
